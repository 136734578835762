import './i18n'

import { ThemeProvider, ToastProvider } from '@flash-tecnologia/hros-web-ui-v2'
import {
  FlagsProvider,
  getUnleashConfig,
} from '@flash-tecnologia/hros-web-utility'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Trans } from 'react-i18next'

import { TrpcProvider } from './api/client'
import AppRouter from './routes'
import { processEnv } from './utils/env'

export default function Root() {
  const unleashConfig = getUnleashConfig({ appName: 'benefit-order' })
  const token =
    processEnv.BUILD_ENV === 'production' ? 'proxy-prod' : 'proxy-dev'
  return (
    <ThemeProvider>
      <ToastProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <TrpcProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <Trans i18nKey="translations">
            <FlagsProvider
              appName={unleashConfig.appName}
              url={unleashConfig.url.toString()}
              refreshIntervalSeconds={unleashConfig.refreshInterval}
              token={token}
            >
              <AppRouter />
            </FlagsProvider>
          </Trans>
        </TrpcProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}
