import { Employee } from '@flash-tecnologia/hros-web-benefit-order-transition'
import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import { BalancesTypes } from 'src/components/checkout/components/balance/balance-tag'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { processEnv } from 'src/utils/env'
import { create } from 'zustand'

export interface IBalance {
  label: BalancesTypes
  value: number
}

export interface ICreditConfigs {
  weekendDaysEnabled: boolean
  holidayDaysEnabled: boolean
  defaultDaysInMonth: number
}

interface IOrderSetup {
  selectedEmployees: Employee[]
  benefitType: BalancesTypes
  isTopup: boolean
}

interface Order {
  paymentMethod: PaymentMethodEnum
  depositDate: dayjs.Dayjs
  dueDate?: dayjs.Dayjs
  cutoffDate?: dayjs.Dayjs
  receiptDescription?: string
}

interface IOrderStore extends IOrderStoreSetters {
  id: string | null
  order: Order | null
  setup: IOrderSetup | null
  pixCode?: string
  companyDepositId?: string
  legacyAuthData?: string
  creditConfigs?: ICreditConfigs | null
}

interface IOrderStoreSetters {
  setOrder: (_order: {
    paymentMethod: PaymentMethodEnum
    depositDate: dayjs.Dayjs
    dueDate?: dayjs.Dayjs
    cutoffDate?: dayjs.Dayjs
    receiptDescription?: string
  }) => Order | null
  setupOrder: (_initialData: IOrderSetup) => IOrderSetup | null
  setPixCode: (_pixCode: string) => void
  setCompanyDepositId: (_companyDepositId: string) => string | undefined
  setCreditConfigs: (
    _initialData: ICreditConfigs,
  ) => ICreditConfigs | null | undefined
}

export const useOrder = create<IOrderStore>((set, get) => ({
  id: null,
  order: null,
  setup: null,
  pixCode: undefined,
  companyDepositId: undefined,
  creditConfigs: null,
  setPixCode: (_pixCode) => set({ pixCode: _pixCode }),
  setOrder: (_order) => {
    set({ order: _order })
    return get().order
  },
  setupOrder: (_initialData) => {
    set({ setup: _initialData })
    return get().setup
  },
  setCompanyDepositId: (_companyDepositId) => {
    set({ companyDepositId: _companyDepositId })
    return get().companyDepositId
  },
  setCreditConfigs: (_initialData) => {
    set({ creditConfigs: _initialData })
    return get().creditConfigs
  },
}))

if (processEnv.BUILD_ENV === 'development') {
  useOrder.subscribe((state) => console.log('state updated', state))
}
