import { z } from 'zod'

export const possibleEnvs = z.enum(['production', 'staging', 'development'])

const env = z.object({ BUILD_ENV: possibleEnvs })

export type PossibleEnvs = z.infer<typeof possibleEnvs>

export const processEnv: Record<keyof z.infer<typeof env>, PossibleEnvs> = {
  BUILD_ENV: process.env.BUILD_ENV as PossibleEnvs,
}
