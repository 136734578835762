import { Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'

export enum OrderStatusEnum {
  PENDING = 'PENDING',
  PAID = 'PAID',
}

type PendingColor = 'yellow'
type PaidColor = 'green'

type OrderStatusColor = PendingColor | PaidColor

interface IOrderColorMap {
  [OrderStatusEnum.PAID]: PaidColor
  [OrderStatusEnum.PENDING]: PendingColor
}

const orderColorStatusMap: IOrderColorMap = {
  [OrderStatusEnum.PAID]: 'green',
  [OrderStatusEnum.PENDING]: 'yellow',
}

export interface IOrderStatusTagProps {
  status: OrderStatusEnum
}

export const OrderStatusTag: React.FC<IOrderStatusTagProps> = ({ status }) => {
  const color: OrderStatusColor = orderColorStatusMap[status]

  return (
    <Tag variant={color} as="label" hasLeftDotIcon>
      <Typography variant="body3">{t(`order.statusTag.${status}`)}</Typography>
    </Tag>
  )
}
