import { useBenefitOrder as useBenefitOrderTransition } from '@flash-tecnologia/hros-web-benefit-order-transition'
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import { GetBalancesResponse } from 'backend/src/routers/finance/procedures/get-balance'
import { GetOrderSummaryResponse } from 'backend/src/services'
import { useEffect, useState } from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { trpc } from 'src/api/client'
import { IBalances } from 'src/components/checkout/components/balance/balance-tag'

import { useOrder } from '../../domain/checkout/context/order-context'
import { useRedirectToLegacy } from '../../utils/hooks'

const isOrderConfirmed = (status: string) => status === 'confirmed'
const isOnConfirmationPage = (pathname: string) =>
  pathname.includes('confirmation')

export const CheckoutGuardRoute = () => {
  const [balances, setBalances] = useState<IBalances[]>([])
  const [summary, setSummary] = useState<GetOrderSummaryResponse>()

  const { benefitType, isTopup, selectedCheckoutEmployeesList } =
    useBenefitOrderTransition()
  const { setupOrder, setCreditConfigs } = useOrder()
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const useCompany = useSelectedCompany()

  useEffect(() => {
    setupOrder({
      benefitType,
      isTopup,
      selectedEmployees: selectedCheckoutEmployeesList,
    })
  }, [selectedCheckoutEmployeesList])

  const { data: balanceData, isLoading: balanceDataIsLoading } =
    trpc.financeServer.getBalance.useQuery({
      balanceType: [benefitType ?? 'plastic'],
    })

  const { data: summaryData, error } = trpc.benefitOrders.getSummary.useQuery(
    { id: params.orderId ?? '' },
    { enabled: !!params.orderId },
  )

  const { data: company } = trpc.benefitOrders.getCompany.useQuery({
    cnpjOrCompanyId: useCompany.selectedCompany.id,
  })

  if (params.orderId) {
    if (error?.message === 'FORBIDDEN') {
      // @to-do show some message to user ?
      useRedirectToLegacy()
    }

    if (summaryData && !summary) {
      setSummary(summaryData)
      if (
        isOrderConfirmed(summaryData?.status) &&
        !isOnConfirmationPage(location.pathname)
      ) {
        navigate(`/checkout/${params.orderId}/confirmation`)
      }
    }
  }

  useEffect(() => {
    if (company?.creditConfigs) {
      setCreditConfigs(company.creditConfigs)
    }
  }, [company, setCreditConfigs])

  useEffect(() => {
    if (balanceData && balanceData.length > 0) {
      const balancesRounded = balanceData.map(
        (balance: GetBalancesResponse) => ({
          label: balance.type,
          value: balance.amount,
        }),
      )

      setBalances(balancesRounded)
    }
  }, [balanceData, isTopup])

  return <Outlet context={{ balances, summary, balanceDataIsLoading }} />
}

export const useOrderData = () =>
  useOutletContext<{
    balances: IBalances[]
    summary: GetOrderSummaryResponse
    balanceDataIsLoading: boolean
  }>()
